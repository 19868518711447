.contactus_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.8);
}
.our_cus{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 12px;
}
.our_cus_border {
    border-bottom: 2.6px solid #920709;
    width: 50px;
    margin: 6px;
    padding: 0;
}
.contact_achievement{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 12px;
    background: #f2f0f0;
    margin-top: 0;
}