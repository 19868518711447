

/* @media screen and (max-width: 750px) { */
    .map{
        display: none;

    }
    .Divv{
        display: flex;
        width: 100%;
    }
    .mb-map{
        display: block;
        width: 90%;
        text-align: center;
        margin: auto;
        margin-top:20px;
    }
    .mb-map .map-mb{
        width: 100%;
    }
    .contain {
        width: 420px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
    }
    .contain>h1 {
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #464343;
        padding: 0;
        margin-top: 16px;
    }
    .contain h1{
        width: 100vw;
        font-size: 20px;
        text-align: center;
    }
    .css-ag7rrr-MuiTypography-root {

        font-size: 12px !important;
        /* margin: 0; */
        /* padding: 0; */
    }
    .css-ahj2mt-MuiTypography-root {
        margin: 0;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        /* word-break: break-all; */
        text-align: justify;
        padding: 0;
    }
    .css-15v22id-MuiAccordionDetails-root {
        padding: 0px 16px 16px !important;
    }
    .css-13si5gj-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
        min-height: 20px !important;
    }
    .list{
        text-decoration: none;
        list-style: none;
        padding-left: 0;
    }
    .contain{
        margin-bottom: 10px;
    }
    .LoadMore{

font-family: 'Quicksand';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
/* identical to box height */


color: #81070F;
text-align: center;
margin-top: 10px;
    }

    .l6,.l7{
        display: none;
    }
/* } */
