.slider-contain{
   
    max-width: 100%;
    height: 300px;
    height: max-content;
    object-fit: cover;
    /* margin-bottom: 30px; */
    margin-top: -12px;
  
}
.slider-contain img{
    /* width: 90vw; */
    max-width: 100%;
}

.alice-carousel__dots {
    margin: -1px 3px 5px;
    padding: 0;
    list-style: none;
    text-align: center;
}
