.infoss {
    margin-left: 10px;
}

.infoss>h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #920709;
    margin: 0%;
    padding: 0;
}
 
.sing {
    display: flex;
    /* align-items: center; */
    margin-top: 10px !important;
}

.sing a {
    text-decoration: none;

    color: grey;
    margin-left: 10px;
}

.infoss img {
    padding-top: 5px;
    width: 17px;
    height: 17px;

}
.infoss a{
    max-width: 80%;
    text-align: left;

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 24px;

color: rgba(0, 0, 0, 0.8);

}
.infoss{
    margin-left: 33px;
}
.infoss>h5{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
text-align: left;
}
.address{
    max-width: 200px;
}
@media screen and (min-width: 1200px){

    .infoss>h5{
       text-align: center;

            }

     .flex__contact{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

    }
    .left,.right{
        flex: 1;
    }
    .mid{
        flex:2;
    }
    
}

.conatctmargin{
    /* background: #f2f0f0; */
    /* margin-top: 0; */
}