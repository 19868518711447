/* @media (max-width:600px) { */

    .slider-1,
    .slider-2,
    .slider-3,
    .slider-4 {
        display:flex;
        flex-direction:column;
        /* max-width: 454px; */
        align-items: center;
        justify-content: center;
        width: calc(100vw - 10vw );
        padding:21px 0;   
        margin: 0 5vw;
        /* margin-top: 60px; */
        /* height: 34px; */
        background: #FFF8F8;
        border-radius: 30px 30px 0px 0px;
        /* position: relative; */
    }
        .testimonial-slider-contain ul {
            margin: 5px 0 0 0 !important;
        }
    .Allocades-1 {
        display: flex;
        width: 100vw;
        margin: auto;
        border-radius: 30px 30px 0px 0px;
        justify-content: space-around;
    }
    .testimonial-slider-contain ul {
        margin: 5px 0 0 0 !important;
    }

    .Allocades-1>img {
        
        width: 100px;
        height: 60px;
        object-fit: contain;
    }

    .heading-2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #920709;
    }

    .para {
        width: 80%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        color: rgba(0, 0, 0, 0.8);

    }

    .testimonial-slider-contain {
        display: flex;
        width: 100vw;
        /* text-align: center; */
        justify-content: center;
        align-items: center;
       
    }

    .testimonial-Allocades-contain {
        display: flex;
        width: 100vw;
        /* text-align: center; */
    }

    .testimonial-slider-contain .alice-carousel__stage-item__cloned {
        display: flex;
        width: 100vw !important;
        justify-content: center;

    }

    .testimonial-slider-contain .alice-carousel__stage-item__cloned {
        width: 100% !important;
    }
    .img-slider{
        /* position: absolute;
        top: -30px; */
        height: 100px;
        width: 100px;
    }
    .testimonial-slider-contain .alice-carousel__stage-item{
        width:100% !important;
        /* display: flex; */
        /* justify-content: center; */
        /* align-items: center; */
    }


/* } */


@media screen and (min-width:1200){

    .slider-1 .para,.slider-2 .para,.slider-3 .para,.slider-4 .para{
        font-size: 18px;
    
    }
   
        
}