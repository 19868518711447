.slider_box{
    padding: 12px;
    width: calc(100vw - 24px);
}
.form_header{
    padding-left: 10px;
}
.modal_action{

    margin-bottom: 10px;
    text-align: center;

}
/* .modal_action img{

} */
.form_header{
    text-align: center;
}
.popup{
    z-index: 100;
    position: absolute;
    top:0px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(122, 119, 119, 0.435);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(218, 217, 217, 0.817);
}
.popup_in{
    /* background-color: rgb(206, 202, 202); */
    border-radius: 8px;
    height:fit-content;
    width: 320px;

}
.popup_in_in{
    position: relative;
    top: 0;
    right: -30px;
    left:90%;
    font-size: 1.7em;
    font-weight: 600;
    

   

}

.modal_action{
   width: 100%;
}
/* .about-img{
    max-width: 90%;
} */

/* .about-image img{
    width: 90em;
} */

.about_header {
    display: flex;
    padding: 0 12px;
    align-items: center;
    justify-content: center;
}

.about_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 30px;
    padding: 12px;
    /* text-align: center; */
    color: rgba(0, 0, 0, 0.8);
}
.our_about{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 12px;
}
.our_about_border {
    border-bottom: 2.6px solid #920709;
    width: 50px;
    margin: 0;
    padding: 0;
}
.aboutschool_header {
    display: flex;
    padding: 0 0;
    align-items: center;
    justify-content: center;
}

.aboutschool_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    padding: 12px;
    /* text-align: center; */
    color: rgba(0, 0, 0, 0.8);
    /* background-color: rgb(120, 235, 149); */
    padding: 0 12px;
}
.our_aboutschool{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* justify-content: center; */
    padding-bottom: 12px;
}
.our_aboutschool_border {
    border-bottom: 2.6px solid #920709;
    width: 50px;
    margin: 6px;
    padding: 0;
}
.club{
    list-style: disc;
}
.contact_achievement{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 12px;
    background: #f2f0f0;
    margin-top: 0;
}

.video{
   
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    /* height: fit-content; */
    /* background-color: #000000; */
}
.video div{
    height: fit-content !important;

}