.Form {
    display: flex;
    flex-direction: column;
    margin-bottom: 21px;
    margin-top: 12px;
    max-width: 600px;
    width: 100%;
}

.Form>h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */
    margin: 0;
    color: rgba(0, 0, 0, 0.8);
}

.Form>h5 {
   padding-bottom: 1em;
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 30px;
/* identical to box height */
margin: 0;

color: rgba(0, 0, 0, 0.8);
}

.form_body>button {
    width: 380px;
    height: 406px;
    left: 24px;
    top: 764px;
    background: #FFFFFF;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.form_body {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    align-items: center;
    margin: 0 1rem 0 1rem;
}

.form_header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    background: #920709;
    border-radius: 20px 20px 0px 0px;
    padding: 12px 0;
    color: #FFFFFF;
    width: 100%;
    margin: 0;
    margin-bottom: 12px;
}

.form_body>input {
    background: #FFFFFF;
    border: 1px solid rgba(144, 144, 144, 0.7);
    border-radius: 20px;
    width: calc(100% - 44px);
    margin: 10px;
    padding: 12px;
    background: #FFFFFF;
    border: 1px solid rgba(144, 144, 144, 0.7);
    border-radius: 20px;
    outline: none;
}

.form_body>button {
    width: 150px;
    height: 45px;
    left: 139px;
    top: 1097px;
    background: #920709;
    border-radius: 20px;
    border: none;
    outline: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 12px 0;
    color: #FFFFFF;
}

.home_copyright {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #920709;
    margin: 12px 0;
}
.white_page{

        width: 100vw;
        height: 100vw;
        background: #FFFFFF;
  

}
