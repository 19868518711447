.phone-m {
    font-size: 24px;
    line-height: 3;
    transform: rotateY(171deg);
    color: rgb(4, 0, 255);
    /* animation-name: example;
    animation-duration: infinity; */
    animation: 3s ease-in 1s infinite reverse both running example;
}

@keyframes example {
    from {
        color: rgb(210, 30, 30);
    }

    to {
        color: rgb(182, 182, 189);
    }

    /* from {color: rgb(17, 255, 0);} */
}



.navbar-toggler:focus {
    /* text-decoration: none; */
    outline: 0;
    /* box-shadow: 0 0 0 0.25rem; */
}

.hover:hover{
    /* background-color: red; */
    /* box-shadow: rgb(182, 182, 189); */
    /* border: 9px solid red; */
}