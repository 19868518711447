.clubs{
    list-style: disc;
}
.exchange_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 30px;
    padding: 12px;
    /* text-align: center; */
    color: rgba(0, 0, 0, 0.8);
}
.trip_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    padding: 12px;
    /* text-align: center; */
    color: rgba(0, 0, 0, 0.8);
}
.comm_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    padding: 12px;
    /* text-align: center; */
    color: rgba(0, 0, 0, 0.8);
}
.contact_achievement{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 12px;
    background: #f2f0f0;
    margin-top: 0;
}