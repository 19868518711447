img{
    object-fit: contain;
}
.home_icon {
    width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.home_icon>h4 {
    font-family: 'Poppins';
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 16px;
    margin: 12px 0;
    margin-bottom: 0;
    color: #920709;
}
.slider_box{

    width: calc(100vw - 24px);
margin: auto;
/* margin-bottom: 12px; */
}
.slider_box>h3 {
    font-family: 'News Cycle';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: #000000;
    margin: 0;
    padding: 0;
}

.slider_box>h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    margin: 0;
    /* padding-top: 12px; */
}
.slider_box>p {
    border-bottom: 2px solid lightgray;
    margin: 12px;
}

.form_box {
    width: calc(100% - 24px);
    margin: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardsDiv {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.achievement_header {
    display: flex;
    padding: 0 12px;
    align-items: center;
    justify-content: center;
}

.achievement_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 30px;
    padding: 12px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
}
.our_achievement{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-bottom: 12px; */
    margin-top: -40px;
}
.our_achievements{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 12px;
}
.our_achievement_border {
    border-bottom: 2.6px solid #920709;
    width: 50px;
    margin: 0;
    padding: 0;
}
.achievement_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.8);
}
.imgDiv{
    flex-wrap: wrap;
}
.imgDiv>img{
    width: 100px;
    object-fit: contain;
}
.videoDiv{
   
    display: flex;
    align-items: center;
    justify-content: center;

    width: 90vw;
    /* height: fit-content; */
    /* background-color: #000000; */
}
.LastLogo{
    display: flex;
     align-items: center;
     justify-content: space-around;
     padding: 12px 0;
}
.lastTwologo{
    display: flex;
    flex-direction: column;
    /* width: 50%; */
}
.videoDiv div{
    height: fit-content !important;

}
.conn {
    margin-top: 7px;

top: 0.938rem;
right: 1.813rem;
}
.hidden {
    width: 24px;
    height: 0px;
    border: 1px solid #000000;
    display: block;
    margin-bottom: 7px;
    cursor: pointer;
    background:black;
}
/* .flexbox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
  


}
.left{
    flex: 1;
} */
/* .form_box{
    flex: 1;
} */

@media screen and (min-width: 1200px){

    .imgDiv{
        max-width: 800px;
        flex-wrap: wrap;

    }
    .LastLogo,.lastTwologo{
        display: inline;
    }
    .flex-lagao{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      
    }
    .flex-lagao .our_achievement, .flex-lagao .videoDiv{
      height: 300px;
      width: 414px;
    }
  .conn,.Nav-items{
      display: none;
  }

}
 li a{

    text-decoration: none;
    color: inherit;
}
li{
    list-style: none;
}
.flexbox_left h3{
    text-align: center;
}
.flexbox_left h5{
    text-align: center;
}
.form_box{
    text-align: center;
}

.home_copyright{
    text-align: center;
}
.testing

{  display: none;
     position: fixed;
top: 0;
left: 0;
background: #000000bd;
width: 100vw;
height: 100vh;
filter: blur(-1px);
}

.rounded-5{
    border-radius: 1em;
}

.bg-para{
    background: rgb(251 250 250);
}

.contact_achievement{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 12px;
    background: #f2f0f0;
    margin-top: 0;
}

